import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

let index = 9;
let currentQuestionNumber = 1;
let questions = gon.questions;
let result = {};

export default class extends Controller {
  static targets = ['timeLine', 'questionNumber', 'questionContent', 'btnAns']

  initialize() {
    this.updateQuestion(currentQuestionNumber)
  }

  connect() {
    this.startRefreshing()
  }

  startRefreshing() {
    this.createQuestion = setInterval(() => {
      this.renderData()
    }, 1000)
  }

  stopRefreshing() {
    $(this.btnAnsTargets).addClass('disabled')
    clearInterval(this.createQuestion)
    this.sendData()
  }

  next(e) {
    var questions_id = questions[currentQuestionNumber - 1]['id']
    var ans_id = e.target.dataset.ans
    result[questions_id] = ans_id
    this.renderData('true')
  }

  renderData(next_ques) {
    if (index < 1 || next_ques == 'true') {
      if (next_ques == undefined) {
        var questions_id = questions[currentQuestionNumber - 1]['id']
        result[questions_id] = '2'
      }
      this.renderQuestion()
      $(this.timeLineTarget).children().removeClass('bg-secondary')

      if (index == 0 || next_ques == 'true') { index = 9; }
    }
    else {
      index--;
      $(this.timeLineTarget).children()[index].classList.add('bg-secondary')
    }
  }

  renderQuestion() {
    if (currentQuestionNumber <= questions.length - 1) {
      currentQuestionNumber++;
      this.updateQuestion(currentQuestionNumber);
    }
    else {
      this.stopRefreshing()
    }
  }

  updateQuestion(questionNumber) {
    const currentQuestion = questions[questionNumber - 1];
    $(this.questionNumberTarget).text(I18n.t('js.questions_number', { index: questionNumber }));
    $(this.questionContentTarget).text(currentQuestion.text);
  }

  sendData() {
    const formData = new FormData();
    formData.append('data', JSON.stringify(result))
    Rails.ajax({
      type: 'post',
      url: '/ffs/qa',
      data: formData
    })
  }
}
