import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  active(event) {
    let currentCheckbox = event.currentTarget.querySelector(
      ".js--sophistications-checkbox"
    );
    let currentCheckboxClassList = currentCheckbox.classList;
    if (currentCheckboxClassList.contains("active")) {
      currentCheckboxClassList.remove("active");
    } else {
      currentCheckboxClassList.add("active");
    }
  }
}
