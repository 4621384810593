import { Controller } from "@hotwired/stimulus";
let currentStep = 1;

export default class extends Controller {
  static targets = [
    "nextBtn",
    "submitBtn",
    "prevBtn",
    "factorTab",
    "factorTabContent",
    "factorTabStep",
    "factorProgressBar",
    "form"
  ];

  initialize() {
    this.showTab("step1");
    this.drawProgressBar(currentStep);
  }

  nextStep() {
    if (currentStep < 6) {
      currentStep++;
      this.activateTab(currentStep);
      this.showElement(this.prevBtnTarget);
    }

    if (currentStep === 6) {
      this.hideElement(this.nextBtnTarget);
      this.showElement(this.submitBtnTarget);
    }
    window.scrollTo(0, 0);
  }

  prevStep() {
    if (currentStep > 1) {
      currentStep--;
      this.activateTab(currentStep);
      this.drawProgressBar(currentStep);
      this.hideElement(this.submitBtnTarget);
      this.showElement(this.nextBtnTarget);
    }

    if (currentStep === 1) {
      this.hideElement(this.prevBtnTarget);
    }
    window.scrollTo(0, 0);
  }

  submitForm() {
    this.formTarget.submit();
  }

  activateTab(step) {
    this.factorTabTarget
      .querySelectorAll("li p")
      .forEach((el) => el.classList.remove("active"));

    for (let i = 1; i <= step; i++) {
      document.getElementById(`step-${i}-tab`).classList.add("active");
    }
    this.drawProgressBar(step);
    this.showTab("step" + step);
  }

  showTab(tabId) {
    this.factorTabStepTarget
      .querySelectorAll(".tab-pane")
      .forEach((el) => el.classList.add("d-none"));
    this.factorTabContentTarget
      .querySelectorAll(".tab-pane")
      .forEach((el) => el.classList.add("d-none"));
    Array.from(document.getElementsByClassName(tabId)).forEach((el) =>
      el.classList.remove("d-none")
    );
  }

  drawProgressBar(step) {
    // $green-primary: #1ca68d
    // $grey-primary-01: #cacaca

    // 6 steps -> 5 spaces
    let drawPercent = (100 / 5) * (step - 1);
    let linearGradient = `linear-gradient(to right, #1ca68d ${drawPercent}%, #cacaca 0%, #cacaca 100%)`;
    this.factorProgressBarTarget.style.background = linearGradient;
  }

  hideElement(element) {
    element.style.display = "none";
  }
  showElement(element) {
    element.style.display = "block";
  }
}
