import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["showVideoBtn", "videoAfterFfs", "video","skipVideo"];

  playVideo() {
    if (videojs.getPlayers()["js--video-after-ffs"]) return;

    this.videoAfterFfsTarget.classList.remove("d-none");
    this.skipVideoTarget.classList.remove("d-none");
    this.showVideoBtnTarget.style.marginBottom = 0;
    const video = videojs("js--video-after-ffs", {
      controlBar: {
        playToggle: true,
        progressControl: {
          seekBar: false
        },
        fullscreenToggle: false,
        pictureInPictureToggle: false
      },
      userActions: {
        doubleClick: false
      },
      playbackRates: [1, 2]
    });
    this.updateUser(video);
  }

  updateUser(object) {
    object.on("ended", () => {
      Rails.ajax({
        url: "users/update",
        dataType: "JSON",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
        },
        type: "PATCH",
        success: (data) => {
          if (data["status"] == "success") {
            window.location.reload();
          } else {
            alert("エラーが発生しました。");
            window.location.reload();
          }
        }
      });
    });
  }
}
