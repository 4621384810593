import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video"];
  static values = {
    url: String
  };

  connect() {
    const video = videojs("js--video-ffs-guidance", {
      controlBar: {
        playToggle: true,
        progressControl: {
          seekBar: false
        },
        fullscreenToggle: false,
        pictureInPictureToggle: false
      },
      userActions: {
        doubleClick: false
      },
      playbackRates: [1, 2]
    });
    video.on("ended", () => {
      window.location.href = this.urlValue;
    });
  }

  replayVideo() {
    const video = videojs("js--video-ffs-guidance");
    video.currentTime(0);
    video.play();
  }
}
