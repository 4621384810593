// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require.context('./static/images', true, /\.(png|jpg|jpeg|svg|ico)$/);

require.context("../images", true);
import Rails from "@rails/ujs";
// require("@rails/ujs").start();
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
import "channels";
// css
import "./styles/application.scss";
// javascript
import "./scripts/application";
import "../controllers";

Rails.start();
import I18n from "i18n-js";
import "../../../public/javascripts/translations";

window.I18n = I18n;
// Turbolinks.start()
// ActiveStorage.start()
