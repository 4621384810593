import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "removeRegisteredRelationships",
    "labelRelationshipType",
    "labelFactType",
    "form"
  ];

  showFormDestroy(e) {
    e.preventDefault();
    const confirmDestroyAlert = document.getElementById(
      "remove-relationships__description"
    );
    const userRelationshipName = e.currentTarget.dataset.userRelationshipName;

    confirmDestroyAlert.innerText = I18n.t(
      "js.user_relationships.description_destroy_alert",
      {
        user_relationships_name: userRelationshipName
      }
    );
    this.formDeleteValue = e.currentTarget.parentElement;

    $("#removeRelationships").modal("show");
  }

  submitFormDestroy() {
    this.formDeleteValue.submit();
  }

  closeFormDestroy() {
    $("#removeRelationships").modal("hide");
  }

  changeRelationshipType(e) {
    const currentLabelRelationshipType = e.currentTarget.nextElementSibling;
    for (let element of this.labelRelationshipTypeTargets) {
      element.classList.remove("active");
    }
    currentLabelRelationshipType.classList.add("active");
  }

  changeFactType(e) {
    const currentLabelFactType = e.currentTarget.nextElementSibling;
    for (let element of this.labelFactTypeTargets) {
      element.classList.remove("active");
    }
    currentLabelFactType.classList.add("active");
  }

  submit() {
    this.formTarget.submit();
  }
}
