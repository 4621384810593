import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["form"];

  active(event) {
    const currentButton = event.currentTarget;
    const currentButtonList = currentButton.parentElement.children;
    if (currentButton.matches(".active")) return;
    for (let element of currentButtonList) {
      element.classList.remove("active");
    }
    currentButton.classList.add("active");
  }

  submit() {
    this.formTarget.submit();
  }
}
